.elanco--form {
  input {
    height: 45px;
    background-color: var(--ion-color-white);
    border: 1px solid #ffffff;
    border-radius: 12px;
    display: block;
    width: 100%;
    padding-left: 20px;
  }

  label {
    color: var(--ion-color-white);
    margin-bottom: 8px;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

ion-button {
  min-width: 200px;
}

ion-checkbox {
  margin-inline-start: 0;
  margin-inline-end: 15px;
  --border-color: #c6c6c6;
  --background: var(--ion-color-white);
}

ion-select {
  height: 45px;
  background-color: var(--ion-color-white);
  border: 1px solid #ffffff;
  border-radius: 12px;

  --padding-end: 15px;

  &::part(icon) {
    opacity: 1;
    position: relative;
    color: white;

    &::before {
      content: '';
      right: 0;
      background-image: url(data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+CgoKPHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTEgMUw3IDdMMTMgMSIgc3Ryb2tlPSIjMDkwQTBBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==);
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      position: absolute;
      width: 14px;
      height: 8px;
      display: block;
      z-index: 10;
    }
  }
}

ion-select-popover {
  --ion-background-color: var(--ion-color-white);
}

.elanco--radio-group {
  ion-item {
    --padding-start: 0;
    --min-height: 40px;
  }
}

ion-radio {
  margin-inline-end: 10px;
  margin-inline-start: 0;
  --color: #c6c6c6;

  &::part(container) {
    background-color: white;
    // --inner-border-radius: white;
  }
}

ion-item.padding-start-0 {
  --padding-start: 0;

  ion-text {
    font-size: 14px;
  }
}

.elanco--item {
  margin-bottom: 20px;
}

.elanco--item-label {
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 8px;

  span {
    display: block;
    font-weight: 400;
  }
}

.elanco--product-item {
  .elanco--item {
    margin-bottom: 10px;
  }

  .elanco--item > label {
    margin-bottom: 2px;
  }
}

.elanco--icon-cruz {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: #0072ce;
}

.elanco--addproduct {
  padding-top: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 40px;
  label {
    color: white;
    margin-bottom: 0;
  }
}

.elanco--item-divider {
  height: 1px;
  width: 100%;
  background-color: #6b6b6b;
  margin-top: 20px;
  margin-bottom: 16px;
}
