.PointsEarned{

  h1 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .elanco--tropy {
    position: absolute;
    width: 100%;
    left: 0;
  }
}

.PointsEarned-total {
  text-align: center;

  h3 {
    font-weight: 700;
    font-size: 80px;
    line-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    text-shadow: 3px 10px 10px rgba(0, 0, 0, 0.25);

    svg {
      margin-top: 18px;
    }
  }

  > span {
    display: block;
    font-size: 14px;
    line-height: 19px;
  }
}
