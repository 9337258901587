.Completed {
  h1 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
  }
}
