/** Ionic CSS Variables **/
ion-button {
  --padding-start: 22px;
  --padding-end: 22px;
  --border-radius: 48px;
  --box-shadow: none;
  height: 43px;
  min-width: 200px;

  &.button-outline::part(native) {
    background-color: white;
    overflow: hidden;
    color: var(--ion-color-secondary);
  }
}

ion-button {
  &:host(.button-outline) {
    --padding-start: 50px;
    --padding-end: 50px;
    --border-radius: 48px;
    height: 43px;
  }
}
