ion-modal {
  --ion-background-color: var(--ion-color-white);
  --height: calc(100%);

  ion-item {
    --min-height: auto;
  }
}

.elanco-modal-header {
  ion-toolbar {
    --padding-end: 0;
    --padding-start: 0;
  }
  &::after {
    display: none;
  }
}

ion-toolbar .elanco-modal-search {
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-left: 20px;
  margin-right: 20px;
  --box-shadow: none;
  border-bottom: 1px solid #c2c2c2;
  width: calc(100% - 40px);

  input.searchbar-input {
    box-shadow: none;
    padding-inline-start: 32px;
  }

  .searchbar-search-icon {
    left: 0;
    color: #c2c2c2;
  }
}

.elanco-modal-close {
  background: none;
  margin-left: auto;
  padding-top: 0.4em;
  padding-right: 12px;
  padding-top: 10px;

  ion-icon {
    width: 25px;
    height: 25px;
  }
}

.elanco-modal-list .item {
  font-weight: 600;
  --padding-start: 20px;
  padding-top: calc(var(--ion-padding) / 5);
  padding-bottom: calc(var(--ion-padding) / 5);
}

.elanco-divider {
  border: none;
  border-bottom: 1px solid #c2c2c2;
  width: 100%;
}

.elanco-modal--address {
  color: #090a0a;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
}

.item--clinic-address {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  display: block;
}
