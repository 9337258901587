.elanco-progress-item {
  width: 100%;

  .elanco-progress-title {
    overflow: unset;
    white-space: unset;
  }

  .elanco-progress-value {
    margin-left: auto;
    text-align: right;
  }
}

ion-item.elanco-progress-item { 
  font-size: 14px;
  font-weight: 700;
  color: var(--ion-color-secondary);
  --min-height: auto;
  padding: var(--padding-start) 0;
  
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--ion-color-white-shade);
  }

  ion-label {
    margin: 0;
  }

  &.elanco-progress-complete {
    font-weight: normal;
    color: #666666;
    background-color: #F4F4F4;
  }

  &::part(native) {
    align-items: flex-start;
  }
}