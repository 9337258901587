.elanco-cardbox {
  ion-card {
    --background: #D9F2F0;
    margin-left: 0;
    margin-right: 0;
  }
  
  ion-card-header {
    padding: 20px;
    margin: 0;
    background-color: #fff;
  }

  ion-img {
    width: 80%;
    height: auto;
    margin: 0 auto
  }
}

.elanco-cardbox--ISI {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  p {
    margin-bottom: 0;
    margin-left: 10px;
  }
  
  svg {
    transform: rotate(180deg);
  }
  
  &.is-active {
    margin-bottom: 20px;
    svg {
      transform: rotate(0deg);
    }
  }
}