.Schedule-page {
  padding-top: 0px;

  .elanco-accordion-content-wrapper {
    padding: 0 var(--padding-start);
  }

  .schedule-event-placeholder {
    border-radius: var(--border-radius, 8px);
    margin-bottom: 0.75rem;
  }
}
