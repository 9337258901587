ion-menu {
  // --background: #EDF3F9;
  --background: transparent;
  --width: calc(100% - 28px);
  ion-list,
  ion-item,
  ion-content {
    // --background: #EDF3F9;
    --background: transparent;
  }

  ion-item {
    --border-color: transparent;
    font-weight: bold;
    font-size: 18px;

    .icon-large {
      margin-inline-end: 23px;
      margin-inline-start: 30px;
      width: 50px;
      height: 50px;
    }
  }
}

.ion-page > .elanco--logo {
  position: absolute;
}

ion-toolbar {
  --padding-end: 13px;
  --padding-start: 13px;
}

.elanco--menu-top {
  background-color: #edf3f9;
  padding-top: 50px;
  padding-bottom: 27px;
}

.elanco--menu-bottom {
  padding-top: 20px;

  ion-item {
    --min-height: 0;
  }

  ion-icon {
    margin-top: 0;
    margin-bottom: 0;
  }

  ion-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.elanco--menu-container {
  top: 45px;
  overflow: hidden;
  border-radius: 0px 20px 0px 0px;
  margin-top: 70px;
  height: calc(100% - 70px);
  box-shadow: rgb(0 0 0 / 18%) 4px 0px 16px;
  background-color: white;
}

ion-menu::part(container) {
  box-shadow: none;
}
// top: 45px;
// border-radius: 0px 20px 0px 0px;
// margin-top: 70px;
// --ion-background-color: #EDF3F9;
// }

.elanco--logo {
  position: relative;
  top: 8px;
  left: 50%;
  margin-left: -42px;
  display: inline-block;
  z-index: 10;
}
