@import "theme/mixins.scss";

.elanco--game-score {
  display: flex;
  gap: 13px;
  padding: 1px 9px;
  margin-bottom: 30px;
  
  align-items: center;
  justify-content: center;
  
  background-color: var(--ion-color-primary);
  border-radius: 8px;
  color: white;
  
  @include phone-sm {
    gap: 8px;
    padding: 6px 9px;
  }
   
  ion-icon {
    color: #61BDFF;
    width: 20px;
    height: 20px;
  }
    
  h4 {
     text-transform: uppercase;
     font-weight: 700;
     font-size: 11px;
     line-height: 12px;
     margin: 0;
     text-align: right;
  }
    
  p {
     text-transform: uppercase;
    font-weight: 700;
    font-size: 27px;
    line-height: 35px;
    margin: 0;
  }
}
  

.game-score--learn {
  cursor: pointer;
  background: var(--ion-color-success);
  border-radius: 8px;
  padding: 0px 10px 3px 10px; 
  text-transform: none;

  span {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    color: #001149;
   }
}
