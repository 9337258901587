.elanco-accordion-item {
  border-radius: 8px;

	.elanco-accordion-content {
		--background: var(--ion-color-white);
   
    &::part(native){
      padding-inline-start: 0;
      padding-inline-end: 0;
      --inner-padding-end: 0;
    }

    &-wrapper {
      width: 100%;
    }
	}

  .elanco-accordion-header {
    --background: var(--ion-color-white);
    border-top: 4px solid var(--ion-color-success);
    color: var(--ion-color-secondary);
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid var(--ion-color-white);
    
    ion-icon {
      color: var(--ion-color-secondary);
      margin: 6px 8px 6px 0;
      width: 26px;
      height: 26px;
    }

    .elanco-accordion-title {
      white-space: unset;
      overflow: unset;
    }

    .elanco-accordion-progress {
      text-align: right;
      font-weight: 400;

      strong {
        font-weight: 700;
      }
    }
  }

  &.accordion-expanded .elanco-accordion-header {
    border-bottom: 1px solid var(--ion-color-white-shade);
  }
}