.Register-page {
  --ion-background-color: var(--ion-color-secondary);

  h1 {
    margin-bottom: 35px;
  }

  ion-item {
    --padding-start: 0;
    --ripple-color: transparent;
    --border-color: transparent;
  }

  ion-text a {
    color: white;
    font-weight: bold;
  }

  .elanco--logo {
    position: relative;
    top: -30px;
  }

  .elanco--form {
    padding-top: 30px;
  }
}
