@import 'theme/mixins.scss';

.ManageProfile-page {
  .elanco--form-myprofile:last-child {
    padding-bottom: 0;
  }
}

.elanco--form-header {
  background-color: #22377e;
  padding: 10px 15px 11px 14px;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include phone-sm {
    padding-right: 10px;
  }
}

.elanco--form-header-edit {
  display: flex;
  column-gap: 8px;
}

.elanco--form-header-icon {
  background: #001149;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-close {
    color: white;
    transform: rotate(45deg);
  }
}

// Form
.elanco--form-myprofile {
  padding-bottom: 30px;

  input {
    height: 32px;
    color: white;
    background-color: var(--ion-background-color) !important;
    outline: none;
    border: none;
    display: block;
    width: 100%;
    padding: 0;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #ffffff;
    border-bottom: 1px dashed #8d8d8d;
    padding-bottom: 10px;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 3px;

    color: #a2a2a2;
  }

  .elanco--item {
    padding-left: 2px;
    padding-right: 2px;
  }

  ion-select {
    background-color: transparent;
    border: none;
    height: 32px;
    color: white;
    border-bottom: 1px dashed #8d8d8d;
    padding-top: 5px;
    border-radius: 0;
    --padding-start: 0;

    &::before {
      content: '';
      right: 0;
      // background-image: url(data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+CgoKPHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTEgMUw3IDdMMTMgMSIgc3Ryb2tlPSIjMDkwQTBBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==);
      background-image: url('./icon-arrow.svg');
      top: 50%;
      right: 30px;
      transform: translate(50%, -50%);
      position: absolute;
      width: 14px;
      height: 8px;
      display: block;
      z-index: 10;
    }

    &::part(icon) {
      display: none;
    }
  }

  form .elanco--item:last-child {
    margin-bottom: 0;
  }
}

.form-myprofile--label {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.form-contact--footer {
  display: flex;
  column-gap: 12px;
  justify-content: center;
  ion-button {
    min-width: 125px;
  }
}

.elanco--form-clinic .no-m-bottom {
  margin-bottom: 0;
}

.elanco--form-customclinic .elanco--item.has-description {
  label {
    margin-bottom: 0;
  }

  > ion-text {
    margin-bottom: 4px;
    display: block;
  }
}
