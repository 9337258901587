.NotConferenceId-page {
  .elanco--logo {
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }

  // footer {
  //   margin-top: 20px;
  // }
}