
.LearnAndEarn-page {
  padding-top: 0px;

  ion-item {
    --padding-start: 0;
    --ripple-color: transparent;
    --border-color: transparent;
    --min-height: 20px;
  }

  ion-item ion-label {
    white-space: wrap;
  }

  ion-checkbox {
    margin-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-inline-end: 15px;
    --border-color: #C6C6C6;
    --background: var(--ion-color-white);
  }

}

ion-alert.elanco--alert--rules {
  --height: 90vh;
  --max-height: 100%;
  
  .alert-message {
    max-height: 100%;
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    text-align: center;
    // color: var(--ion-color-primary);
    margin-bottom: 20px;
  }
}
