
   
$spaces: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
20;

$sides: 'top', 'right', 'bottom', 'left';

@each $space in $spaces {
.m-#{$space} {
  margin: #{calc($space /4)}rem;
}

.mx-#{$space} {
  margin-left: #{calc($space /4)}rem;
  margin-right: #{calc($space /4)}rem;
}

.my-#{$space} {
  margin-top: #{calc($space /4)}rem;
  margin-bottom: #{calc($space /4)}rem;
}

.px-#{$space} {
  padding-left: #{calc($space /4)}rem;
  padding-right: #{calc($space /4)}rem;
}

.py-#{$space} {
  padding-top: #{calc($space /4)}rem;
  padding-bottom: #{calc($space /4)}rem;
}

.p-#{$space} {
  padding: #{calc($space /4)}rem;
}

@each $side in $sides {
  .m#{str-slice($side, 0, 1)}-#{$space} {
    margin-#{$side}: #{calc($space /4)}rem;
  }

  .p#{str-slice($side, 0, 1)}-#{$space} {
    padding-#{$side}: #{calc($space /4)}rem;
  }
}
}