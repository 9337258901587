/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import 'theme/mixins.scss';

/** Ionic CSS Variables **/
:root {
  /** global **/
  --ion-font-family: 'Open Sans';
  --ion-padding: 26px;
  --ion-background-color: #001149;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** primary **/
  --ion-color-primary: #0072ce;
  --ion-color-primary-rgb: 0, 114, 206;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0064b5;
  --ion-color-primary-tint: #1a80d3;

  /** secondary **/
  --ion-color-secondary: #001149;
  --ion-color-secondary-rgb: 0, 17, 73;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000f40;
  --ion-color-secondary-tint: #1a295b;

  --ion-color-success: #0ec3a4;
  --ion-color-success-rgb: 14, 195, 164;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #0cac90;
  --ion-color-success-tint: #26c9ad;

  /** danger **/
  --ion-color-danger: #ff3033;
  --ion-color-danger-rgb: 255, 48, 51;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e02a2d;
  --ion-color-danger-tint: #ff4547;

  // XSmall Devices

  @include phone-sm {
    --ion-padding: 22px;
  }
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
