.elanco-confetti {
  position: relative;
  canvas {
    width: 100%;
    height: 100%;
    position: fixed;
    top: -25%;
    left: 0;
    display: block;
    z-index: -1;
  }
}