ion-alert.elanco--alert {
  --background: #edf4f9;
  --backdrop-opacity: 0.8;
  --max-width: 352px;

  .alert-wrapper {
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding-bottom: 24px;
  }

  h2.alert-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 10px;
  }

  div.alert-message {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
  }

  .alert-button-group {
    justify-content: center;
  }

  button.alert-button {
    height: 43px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-white);
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 48px;
    min-width: 200px;
    margin-right: 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.075em;
    &:not(:last-child) {
      margin-top: 15px;
    }

    &.button-outline {
      background-color: white;
      overflow: hidden;
      color: var(--ion-color-secondary);
      border: 2px solid var(--ion-color-primary);
    }

    > span {
      // display: none;
      justify-content: center;
    }
  }
}

.game-rules--link p {
  color: var(--ion-color-primary);
  margin-top: 5px;
  font-weight: 600;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
}

.game-rules--button {
  font-weight: bold;
  background: transparent;
  text-decoration: underline;
  border: none;
  color: currentColor;
  font-size: 16px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  color: var(--ion-color-white);
}

ion-alert.elanco--alert-confirm {
  --background: #edf4f9;
  --backdrop-opacity: 0.8;
  --max-width: 352px;

  .alert-wrapper {
    width: calc(100% - 20px);
    border-radius: 20px;
    padding-bottom: 24px;
  }

  h2.alert-title {
    font-weight: 400;
    max-width: 220px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 10px;
    color: #090a0a;
  }

  div.alert-message h4 {
    margin: 0 auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #090a0a;
    text-transform: capitalize;
  }

  .alert-button-group {
    // flex-direction: row;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  button.alert-button {
    height: 43px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-white);
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 48px;
    min-width: 136px;
    margin-right: 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.075em;

    &.button-outline {
      background-color: white;
      overflow: hidden;
      color: var(--ion-color-secondary);
      border: 2px solid var(--ion-color-primary);
    }

    > span {
      justify-content: center;
    }
  }
}

ion-alert.elanco--alert-order {
  p {
    margin-bottom: 0;
  }

  button.alert-button {
    min-width: 266px;
  }
}

ion-alert.elanco--alert-delete {
  div.alert-message h4 {
    font-size: 20px;
    line-height: 24px;
    max-width: 240px;
    text-transform: none;
  }
}
