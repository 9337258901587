h1,
.text-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 12px;
}

h2,
.text-h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-top: 0;
  margin-bottom: 26px;
}

p,
ul {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: inherit;

  p {
    margin-bottom: 0;
  }
}

.list--plain {
  list-style: none;
  padding-left: 0;
}

.text-medium {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.text-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.text-tiny {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.text-smallcopy {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.text-copy {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.text-underline {
  text-decoration: underline;
}

.phone-link,
.inline-link {
  color: inherit;
  white-space: nowrap;
}
