.Login-page {
  --ion-background-color: var(--ion-color-secondary);

  h1 {
    margin-bottom: 35px;
  }
  
  ion-item {
    --padding-start: 0;
    --ripple-color: transparent;
    --border-color: transparent;
  }

  ion-text a {
    color: white;
    font-weight: bold;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

}

