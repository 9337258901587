.elanco-cardbutton {
  border-radius: 8px;
  border-top: 4px solid var(--ion-color-success);

  &::part(native) {
    min-height: 120px;
  }

  .icon-large {
    width: 60px;
    height: 60px;
  }

  &__content {
    padding: 20px 0 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    svg {
      margin-left: auto;
      margin-top: auto;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin: auto 0 0;
  }

  &__description {
    margin: 0;
  }
}

.elanco-cardbutton__placeholder {
  margin: auto 0 0;
}

.is-product {
  &.elanco-cardbutton {
    --inner-border-width: 0;
    padding: 0;
    position: relative;

    &::part(native) {
      min-height: 55px;
      padding-top: 15px;
      padding-bottom: 15px;
      border: 0;
    }
  }

  .elanco-cardbutton__title {
    margin-top: 0;
    padding-right: 45px;
  }

  .elanco-cardbutton__content {
    padding: 0;
    height: auto;
    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -5px;
    }
  }
}
