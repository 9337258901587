.elanco--tropy {
  position: relative;
  padding-top: 140px;

  canvas {
    width: 100%;
    height: 400px;
    position: fixed;
    top: 50px;
    left: 0;
    display: block;
    z-index: -1;
  }

  img {
    position: relative;
    z-index: 10;
  }
}

.elanco--tropy-lights {
  position: absolute;
  top: -154px;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: -1;

  &.is-active {
    img {
      max-height: 400px;
      opacity: 1;

      &:nth-child(4), &:nth-child(5) {
        transition-delay: 3s;
      }
    }
  }
  
  img {
    position: absolute;
    top: 0;
    z-index: -1;
    max-height: 0;    
    opacity: 0;
    transition: max-height 400ms, opacity 700ms;  
        
    // middle light
    &:nth-child(1) {
      left: 0;
      transition-delay: 0;
    }
    
    // center light
    &:nth-child(2) {
      left: 50%;
      transition-delay: 200ms;
      transform: translateX(-50%);
    }
    
    // right light
    &:nth-child(3) {
      transition-delay: 400ms;
      right: 0;
    }

    // left confeti
    &:nth-child(4) {
      top: 290px;
      left: 30px;
    }

    // left confeti
    &:nth-child(5) {
      top: 278px;
      right: 10px;
    }

  }
}
