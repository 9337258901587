.elanco-cardprize {
  border-top: 5px solid var(--ion-color-success);
  margin-inline: 0;
  // overflow: visible;
  
  // ion-card-content {
  //   overflow: visible;
  // }

  h3, h4, p {
    margin: 0;
  }

  h3 {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 7px;
  }

  .elanco-cardprize--arrows {
    display: none;
  }
  
  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 10px;
    text-transform: capitalize;
  }

  .elanco-cardprize--points {
    font-style: italic;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
  }
}

.elanco-cardprize--img {
  background-color: #F1F1F1;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.elanco-cardprize--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elanco-cardprize--level {
  position: relative;
  svg {
    position: absolute;
    top: -10px;
    margin-left: 5px;
    &:hover {
      color: var(--ion-color-success);
    }
  }

}

.elanco-cardprize--raffle {
  background-color: #CBECFF;
  padding: 16px;
  p, svg, ul {
    color: var(--ion-color-secondary) !important;
    font-weight: bold;
    text-transform: none;
  }

  p, ul li {
    font-size: 14px;
    line-height: 19px;
  }

  svg {
    width: 40px;
    height: 35px;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }

  .elanco-cardprize--raffle--title {
    display: flex;
    align-items: center;
  }
}

ion-card.is-completed {
  // opacity: 0.6;
  pointer-events: none;
  &::part(native) {
    background-color: #979797;
  }

  .elanco-cardprize--img { 
    background-color: #AFAFAF;
    opacity: 0.5;
  }

  h4 {
    text-decoration: line-through
  }
}

ion-card.is-current {
  border: 5px solid var(--ion-color-success);
  
  &::part(native) {
    background-color: var(--ion-color-primary);
  }
  
  h4, h3, p {
    color: var(--ion-color-white);
    svg {
      color: var(--ion-color-secondary)
    }
  }
  // arrows
  ion-card-content {
    border-top: 0;
    position: relative;
  }

  // .elanco-cardprize--arrows {
  //   position: absolute;
  //   height: 100%;
  //   width: 18px;
  //   top: 0;
  //   left: -27px;
  //   border-top-right-radius: 6px;
  //   border-bottom-right-radius: 6px;
    
  //   background-color: var(--ion-color-primary);
  //   color: var(--ion-color-white);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   &.is-right {
  //     border-radius: 0;
  //     border-top-left-radius: 6px;
  //     border-bottom-left-radius: 6px;
  //     left: auto;
  //     right: -27px;

  //     svg {
  //       transform: rotate(180deg);
  //     }
  //   }
  // }
} 
