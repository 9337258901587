.VisitMyElanco-page {
  padding-top: 0px;

  ion-img {
    height: 190px;
    width: 100%;
    object-fit: cover;
  }

  ion-button {
    min-width: 242px;
  }
}
