.Welcome-page {
  --ion-background-color: var(--ion-color-white);
  footer {
    padding: 0;
  }

  ion-content {
    display: flex;
  }
}

.welcome-content {
  padding-top: 50px;
  text-align: center;

  p {
    display: block;
    margin: 0;
    font-size: 12px;
  }
}

.welcome-container {
  margin-top: 45px;
  padding: 40px 0 30px;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -155px;
    left: -30px;
    right: -30px;
    height: 190px;
    background-color: var(--ion-color-secondary);
    z-index: -2;
  }
  &::before {
    top: 0;
    left: -50%;
    right: auto;
    width: 200%;
    height: 100%;
    background-color: var(--ion-color-primary);
    transform: rotate(-8deg);
    z-index: -1;
  }
}
