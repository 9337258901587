.ContactUs-page {
  padding-top: 0px;
  height: 100%;

  h2 {
    margin-bottom: 10px;
  }

  ion-item {
    --padding-start: 0;
    --ripple-color: transparent;
    --border-color: transparent;
    --min-height: 20px;
  }

  ion-item ion-label {
    white-space: wrap;
    margin: 5px 0;
  }

  ion-checkbox {
    margin-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-inline-end: 15px;
    --border-color: #C6C6C6;
    --background: var(--ion-color-white);

    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  .elanco-checkbox {
    align-items: flex-start;
    margin-top: 30px;

    ion-label {
      margin-top: 0;
    }
  }
}
